.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 1vmin);
  color: white;
}

section.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}

h2 {
  font-size: 36px;
}

.form {
  border: 1px solid #ddd;
  margin: 30px 0px; 
}

input {
  border: none;
  padding: 10px 10px 0px;
  font-size: 20px;
  max-width: 180px;
  outline: none;
}

button {
  background: #553c9a;
  color: #fff;
  font-size: 18px;
  height:100%;
  padding: 8px 12px;
  cursor: pointer;
}

section.data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
 
}
section.data p.def {
  width:320px;
  color: #203748;
  line-height: 1.6;
}

.hidden {
  display:none;
}

.suggestions {
  width:320px;
  margin-top:16px;
}

.suggested {
  background: #B794F4;
  color:#fff;
  padding: 0px 10px 4px 10px;
  border-radius: 4px;
  margin-right:10px;
  margin-top:5px;
  display:inline-block;
  border:none;
  outline:none;
}

.audio {
  margin-top: 20px;
}